import React, { useState } from 'react';
import Snowfall from 'react-snowfall';
import AudioPlayer from './AudioPlayer';
import Popup from './Popup'; // Import the Popup component
import logo from './logo.png'; // Assuming logo is in the src folder
import './App.css';

const App = () => {
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State for showing the popup

  const handleUserInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
  };

  return (
    <div className="app-container" onClick={handleUserInteraction} onScroll={handleUserInteraction}>
      <Snowfall />
      {hasInteracted && <AudioPlayer src="/audio/song1.mp3" />}
      <img src={logo} alt="Logo" className="logo" /> {/* Add this line */}
      <header className="header-content">
        <h1>BabySanta AI</h1>
        <h2> Source of Crypto Cheer!🎄🎅</h2>
        <p>Ho Ho HODL! 🚀 Get ready to unwrap the joy of the holiday season with a digital twist!
       </p>
      </header>
      <button className="connect-wallet">Connect Wallet</button>
      <div className="buttons-container">
        <button className="buy-now">Buy Now</button>
        <button className="chart">Chart</button>
        <button className="learn-more" onClick={() => setShowPopup(true)}>Learn More</button> {/* Learn More button */}
      </div>
      <Popup isVisible={showPopup} onClose={() => setShowPopup(false)} /> {/* Popup component */}
    </div>
  );
};

export default App;
