import React from 'react';
import './Popup.css';

const Popup = ({ onClose, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content scrollbar-style"> {/* Here's where you add the class */}
        <button onClick={onClose} className="close-popup">X</button>
        <h2 className="popup-title">Welcome to BabySanta AI</h2>
        <p className="popup-text">
          DETAILS:
        </p>
        <p className="popup-text">
          <strong>SMART CONTRACT:</strong> PLEASEHOLDER CONTRACT<br/>
          <strong>LP LOCK:</strong> 🔒 | <strong>CONTRACT:</strong> <a href="[link]" target="_blank">[link]</a>
        </p>
        <p className="popup-text">
          You might be thinking, oh my god, another dumb meme, but hold up, even though this is a meme, the tech is real (more than can be said for Fetch AI lol). In search of an alternative to an IDO (presale), we decided to launch a few high-quality meme coins with different use cases, and this is one of them.
        </p>
        <p className="popup-text">
          Santa Claus has real AI capabilities, perfect for the holidays. You can play with it, enjoy features, make some gains, and what's more important, you get an airdrop for the real Tech project. All of this because everything we do is open source, no OpenAI API... or other gimmicks.
        </p>
        <p className="popup-text">
          AND YES IT'S FAIR LAUNCHED... I added 100% supply into the LP and donate the Liquidity, yes its locked [link]
        </p>
        <h3 className="popup-subtitle">How It Works:</h3>
        <ul className="popup-list">
          <li>We have 3 snapshots on different days to reflect your token holdings.</li>
          <li>Low tax, fixed in the smart contract, part of the launch LP.</li>
          <li>Two more snapshots around Chinese New Year, just before the launch of our main product.</li>
        </ul>
        <h3 className="popup-subtitle">Features of Baby Santa AI:</h3>
        <ul className="popup-list">
          <li>Real AI: Talk and interact with our AI, capable of creating Christmas greetings, cards, and even videos.</li>
          <li>Perfect for surprises for your degen friends and family.</li>
          <li>Unique and innovative approach to blockchain technology.</li>
        </ul>
        <p className="popup-text">
          "LETS make Christmas Great Again.. Cheers, The Dev"
        </p>
      </div>
    </div>
  );
};

export default Popup;
