import React, { useState, useEffect, useRef } from 'react';
import './AudioPlayer.css'; // Make sure this CSS file has the required styles

const AudioPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.3); // Initial volume set to 30%
  const audioRef = useRef(new Audio(src));

  // Effect for initializing the audio element
  useEffect(() => {
    // Set the initial volume
    audioRef.current.volume = volume;

    const audio = audioRef.current;

    // Play audio when audioRef is set and hasInteracted is true
    const playAudio = async () => {
      try {
        await audio.play();
        setIsPlaying(true);
      } catch (error) {
        console.error('Playback was prevented:', error);
      }
    };

    playAudio();

    // Cleanup function to pause and unload the audio when the component unmounts
    return () => {
      audio.pause();
    };
  }, [src]); // Only re-run the effect if src changes

  // Effect for handling volume changes
  useEffect(() => {
    audioRef.current.volume = volume;
  }, [volume]); // Only re-run the effect if volume changes

  // Function to toggle play/pause
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Function to update the volume
  const changeVolume = (e) => {
    setVolume(e.target.value);
  };

  return (
    <div className="audio-player">
      <button onClick={togglePlayPause} className="play-pause-btn">
        {isPlaying ? 'Pause' : 'Play'}
      </button>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={changeVolume}
        className="volume-slider"
      />
    </div>
  );
};

export default AudioPlayer;
